const main = "main"
const postCheck = "post-check"
const transactionHistory = "transaction-history"
const customerDetails = (customerId=':customerId') => `customer-details/${customerId}`
const historyDetails = (historyId=':historyId') => `transaction-history-details/${historyId}`


export {
    main,
    postCheck,
    transactionHistory,
    customerDetails,
    historyDetails
}