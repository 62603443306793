import React, { useState, useEffect, useRef } from 'react'

// styles
import { WrapFace } from './index.styles'

export default function FaceZoom({url, className, refList}) {
    const refFace = useRef(null) 
    const refPopup = useRef(null)

    const [isShowZoomFace, setIsShowZoomFace] = useState(false)
    const [isOutList, setIsOutList] = useState('')

    useEffect(() => {
        if (!refList) return;
        if (isShowZoomFace && refList) {
            const listRect = refList.current.getBoundingClientRect() // ref list
            const { height, top } = listRect
            const faceRect = refFace.current.getBoundingClientRect()
            
            const PopupRect = refPopup.current.getBoundingClientRect() // ref popup
            
            const hightY = height + top
            const isOutY = PopupRect.top + PopupRect.height
            if (isOutY > hightY) {
                let newTopPopup = faceRect.bottom-269
                if(newTopPopup < top) {
                    let delta = top - newTopPopup
                    setIsOutList(`-${delta}px`)
                }
                else setIsOutList('0px')
            }
        } else {
            setIsOutList('')
        }

    }, [isShowZoomFace, refList])

    const _handleShowZoomFace = (event) => {
        event.stopPropagation();
        setIsShowZoomFace(!isShowZoomFace)
    }

    return (
        <WrapFace
            className={className}
            ref={refFace}
            propsPopup={isOutList}
        >
            <img src={url} alt='face'
                onMouseEnter={_handleShowZoomFace}
                onMouseLeave={()=>setIsShowZoomFace(false)} 
                />
            {
                isShowZoomFace &&
                
                <img
                    src={url}
                    className="wrap-face-zoom"
                    ref={refPopup}
                />
            }
        </WrapFace>
    )
}
