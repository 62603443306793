import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// action
import { popNotify } from '../../../redux/notifies/action'
import { logoutAction } from '../../../redux/login/action'

// constant
// import { AUTHENTICATED_ERR } from '../../../redux/notifies/types'

// styles
import { WrapNotify } from './index.styles'

// component
import Toast from './Toast'

// constant
import { toastTypes } from './Toast/index.styles'

// type
import * as typePostCheck from '../../../redux/postcheck/types'
import * as typeNotify from '../../../redux/notifies/types'

// helpper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'

const messages = {
    TEST_SUCCESS: "TEST_SUCCESS",
    TEST_ERR: "TEST_ERR",
    [typeNotify.NETWORK_ERR]: "Mất kết nối với hệ thống",
    [typeNotify.SYSTEM_ERR]: "Đã xảy ra lỗi hệ thống. Vui lòng thử lại!",
    [typePostCheck.UPDATE_CHECK_POST_SUCCESS]: "Hậu kiểm thành công",
    [typePostCheck.UPDATE_CHECK_POST_ERROR]: "Hậu kiểm không thành công",
    [typePostCheck.UPDATE_FACE_POST_CHECK_SUCCESS]: "Cập nhật khuôn mặt thành công",
    [typePostCheck.UPDATE_FACE_POST_CHECK_ERROR]: "Cập nhật khuôn mặt không thành công",
    "43": "Thông tin khách hàng này đã được hậu kiểm"
}

const Notify = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { notifiesReducer, dataLogin } = useSelector(state => ({
        notifiesReducer: state.notifies,
        dataLogin: state.login.dataLogin
    }))
    const [currNotify, setCurrNotify] = useState(null);

    const _handleClosePopup = () => {
        setCurrNotify(null)
        dispatch(popNotify(currNotify.type))
    }

    useEffect(() => {
        if(currNotify) return;
        setCurrNotify(Object.values(notifiesReducer)[0])
    }, [notifiesReducer]);

    useEffect(() => {
        if(!currNotify) return;
        if(currNotify.type !== typeNotify.AUTHENTICATED_ERR) return;
        dispatch(logoutAction())
    }, [currNotify]);

    const notify = useMemo(() => {
        if(!currNotify) return null;
        const type = (() => {
            const isSuccess = /.*_SUCCESS/.test(currNotify.type)
            if(isSuccess)
                return toastTypes.SUCCESS
            
            // const isError = /.*_ERR/.test(currNotify.type)
            // if(isError) 
            return toastTypes.ERROR
        })()

        const code = currNotify?.payload?.code
        const message = (() => {
            if(checkIsSuccessAction(code)){
                return messages[currNotify.type]
            }
            else{
                return messages[code] ||  messages[currNotify.type] || `Đã có lỗi xảy ra, code ${messages[code]}`
            }
        })()
        if(!message) return null

        return <Toast type={type} onClose={_handleClosePopup}>{message}</Toast>
    }, [currNotify])

    return (
        <WrapNotify>
            {notify}
        </WrapNotify>
    )
}

export default Notify
