import * as types from './types'
import { LOGOUT } from '../login/types'
import moment from 'moment';
import { listStatusTypeTransaction } from '../../data/dataTransactionStatus';
import { listStatusTransactionOTP } from '../../data/dataTransactionOTP';

const defaultStartDate = moment().startOf("month").toDate();
const defaultEndDate = moment(new Date()).endOf("day").toDate();

const initialState = {
    listTransaction: [],
    detailTransaction: {},
    totalListTransaction: 0,
    condFilter: {
        id: "",
        filterFrom: "",
        filterSkip: 0,
        filterSize: 15,
    },
    condFilterList: {
        id: "",
        filterFrom: "",
        filterSkip: 0,
        filterSize: 15,
        keySearch: "",
        type: listStatusTransactionOTP[0].key,
        paymentStatus: listStatusTypeTransaction[0].key,
        startTime: defaultStartDate,
        endTime: defaultEndDate,
    }
}

const transaction = (state = initialState , action) => {
    switch (action.type) {
        case types.GET_LIST_TRANSACTION:
            return {
                ...state,
                ...action.payload,
                listTransaction: action.payload.isScrolling ? [...state.listTransaction, ...action.payload.listTransaction] : action.payload.listTransaction,
            }

        case types.CLEAR_LIST_TRANSACTION:
            return {
                ...state,
                listTransaction: [],
                condFilter: initialState.condFilter,
                condFilterList: initialState.condFilterList
            }
        case types.RESET_CONDFILTER_TRANSACTION: {
            return {
                ...state,
                condFilter: initialState.condFilter,
                condFilterList: initialState.condFilterList
            }
        }
        case types.GET_DETAIL_TRANSACTION:
            return {
                ...state,
                detailTransaction: action.detailTransaction,
            }
        case types.CLEAR_DETAIL_TRANSACTION:
            return {
                ...state,
                detailTransaction: {}
            }
        case types.COUNT_TRANSACTION:
            return {
                ...state,
                totalListTransaction: action.totalListTransaction
            }
        case LOGOUT:
            return initialState

        default:
            return state
    }
}

export default transaction