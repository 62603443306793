import React from "react";

export default function IcCheckUserWhite(props) {
  return (
    <svg
      {...props}
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_682_2824)">
        <path
          d="M9.8335 12.25V11.0833C9.8335 10.4645 9.58766 9.871 9.15008 9.43342C8.71249 8.99583 8.119 8.75 7.50016 8.75H3.41683C2.79799 8.75 2.2045 8.99583 1.76691 9.43342C1.32933 9.871 1.0835 10.4645 1.0835 11.0833V12.25"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4165 6.41667L11.5832 7.58333L13.9165 5.25"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.45833 6.41667C6.747 6.41667 7.79167 5.372 7.79167 4.08333C7.79167 2.79467 6.747 1.75 5.45833 1.75C4.16967 1.75 3.125 2.79467 3.125 4.08333C3.125 5.372 4.16967 6.41667 5.45833 6.41667Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_682_2824">
          <rect
            width={14}
            height={14}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
