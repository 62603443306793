import { main, postCheck, transactionHistory, customerDetails, historyDetails } from '../constant/routes'

// image
import IcPostCheck from '../assets/images/IcPostCheck'
import IcPostCheckActive from '../assets/images/IcPostCheckActive'
import IcTransactionHistoryActive from '../assets/images/IcTransactionHistoryActive'

// action
import { resetPostCheckCondFilter } from '../redux/postcheck/action'

const dataMenu = [
    {
        path: `/${main}/${postCheck}`,
        Icon: IcPostCheck,
        IconActive: IcPostCheckActive,
        name: 'Hậu kiểm',
        key: postCheck
    },
    {
        path: `/${main}/${transactionHistory}`,
        Icon: IcTransactionHistoryActive,
        IconActive: IcTransactionHistoryActive,
        name: 'Lịch sử giao dịch',
        key: transactionHistory
    },
    // {
    //     path: `/${main}/${historyDetails(123)}`,
    //     Icon: IcPostCheck,
    //     IconActive: IcPostCheck,
    //     name: 'Chi tiết LS'
    // },
]

export default dataMenu