import React, { useRef } from 'react'


//style
import { WrapCheckBox } from './index.styles'

//image
import IcCheckBoxActive from '../../../assets/images/IcCheckBoxActive';
import IcUnCheckBox from '../../../assets/images/IcUnCheckBox';


const CheckBoxControl = ({
    className,
    listItem,
    valueSelected,
    setValueSelect,
    onCheck
}) => {

    const eleRef = useRef()

    return (
        <WrapCheckBox
            ref={eleRef}
            className={className}
        >
            {
                listItem.map((item) => {
                    const { text, key } = item
                    return (
                        <div className="wrap-checkbox" key={key} onClick={() => setValueSelect(item)}>
                            {
                                valueSelected?.key === key ?
                                    < IcCheckBoxActive className="icon" />
                                    : <IcUnCheckBox className="icon" />
                            }
                            <p className="text">{text}</p>
                        </div>
                    )
                })
            }
        </WrapCheckBox>
    )
}

export default CheckBoxControl
