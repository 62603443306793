import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// css
import { DeltailBlockContainer } from '../../../mainStyles'

//helper
import { convertDateTime } from '../../../../helper/convertDateTime'
import { checkStatus } from '../../../../helper/checkStatus'
import { apiGetAvatar } from '../../../../constant/host'

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function CustomerDetailBlock({ data }) {
    let query = useQuery();
    let customerId = query.get("customerId");

    const {totalListTransaction} = useSelector(state => ({
        totalListTransaction: state.transaction.totalListTransaction
    }))

    return (
        <DeltailBlockContainer>
            <div className='detail-container'>
                <div className='face-block'>
                    <img src={apiGetAvatar(data?.avatarId)} alt="imgTesst"/>    
                </div>
                <div className='info-block post-check-info'>
                    <div className='info-block-item'>
                        <p className='p-value' title={data?.customerId}>{data?.customerId}</p>
                        <p className='p-title'>Số CIF</p>
                    </div>
                    {
                        customerId?.length > 0 ?
                        <div className='info-block-item'>
                            <p className='p-value'>{totalListTransaction > 0 ? totalListTransaction : '0'}</p>
                            <p className='p-title'>Số lượng giao dịch</p>
                        </div>
                        :
                        <div className='info-block-item'>
                            <p className={checkStatus(data?.reviewStatus)?.classNameStatus}>{checkStatus(data?.reviewStatus)?.textStatus}</p>
                            <p className='p-title'>Trạng thái </p>
                        </div>
                    }
                    
                    {
                        data?.reviewStatus !== 0 ?
                        <div className='info-block-item'>
                            <p className='p-value' title={data?.reviewer}>{data?.reviewer}</p>
                            <p className='p-title'>Nhân viên hậu kiểm</p>
                        </div>
                        :
                        <div></div>
                    }
                    <div className='info-block-item'>
                        <p className='p-value'>{data?.createAt ? convertDateTime(data?.createAt, "DD/MM/YYYY - HH:mm") : ""}</p>
                        <p className='p-title'>Thời gian đăng ký</p>
                    </div>
                    {
                        customerId?.length > 0 ?
                        <div className='info-block-item'>
                            <p className={checkStatus(data?.reviewStatus)?.classNameStatus}>{checkStatus(data?.reviewStatus)?.textStatus}</p>
                            <p className='p-title'>Trạng thái </p>
                        </div>
                        :
                        <div></div>
                    }
                    {
                        data?.reviewStatus !== 0 ?
                        <div className='info-block-item'>
                            <p className='p-value'>{data?.reviewAt ? convertDateTime(data?.reviewAt, "DD/MM/YYYY - HH:mm") : ""}</p>
                            <p className='p-title'>Thời gian hậu kiểm</p>
                        </div>
                        :
                        <div></div>
                    }
                    {
                        (data?.reviewStatus === 2 || data?.reviewStatus === 3) ?
                        <div className='info-block-item cause'>
                            <p className='p-value'>{data?.reviewDesc ? data?.reviewDesc : "-"}</p>
                            <p className='p-title'>Lý do thất bại</p>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            </div>
        </DeltailBlockContainer>
    )
}
