import React from "react";

export default function IcBtnSubmitWhite(props) {
  return (
    <svg
      {...props}
      width={19}
      height={18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 17.25L2.75 14.25L5.75 11.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 9.75V11.25C16.25 12.0456 15.9339 12.8087 15.3713 13.3713C14.8087 13.9339 14.0456 14.25 13.25 14.25H2.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 0.75L16.25 3.75L13.25 6.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 8.25V6.75C2.75 5.95435 3.06607 5.19129 3.62868 4.62868C4.19129 4.06607 4.95435 3.75 5.75 3.75H16.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
