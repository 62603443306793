import  Axios  from 'axios'
import * as types from './types'
import { host } from '../../constant/host'
import * as path from '../../constant/path'
import { OCB_ADMIN_LANG, OCB_ADMIN_LOGIN } from "../../constant/keys";
import checkIsSuccessAction from '../../helper/checkIsSuccessAction';
import checkSessionLogin from '../../helper/checkSessionLogin';

const apiLogin = `${host}/portal/access/login`
const apiLogout = `${host}/portal/access/logout`

const headerDataRequest = {
    cId: 'WebPortal',
    reqId: `${new Date().getTime()}`,
    langCode: 'vn'
}

let isCalling = false;
export const loginAction = (navigate , dataLogin , callbackSuccess , callbackError) => async (dispatch) => {
    if(isCalling) return;
    isCalling = true;

    const dataRequest = {...headerDataRequest, data: dataLogin}
    const requestData = JSON.stringify(dataRequest)

    try {
        const res = await Axios.post(apiLogin , requestData)
        const {data} = res
        const {code} = data.data
        if(checkIsSuccessAction(code)){
            const {uuid , fullname , listRules = {}, token} = data.data
            let dataStore = {
                username: dataLogin.username, 
                sessionKey: dataRequest.reqId,
                uuid , fullname , listRules, token
            }
            localStorage.setItem(OCB_ADMIN_LANG, JSON.stringify({langKey : 'vn'}))
            localStorage.setItem(OCB_ADMIN_LOGIN, JSON.stringify(dataStore))
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            dispatch({
                type: types.LOGIN,
                data: dataStore,
                // data,
            });
            callbackSuccess && callbackSuccess()
            navigate(`/${path.MAIN}`);
        }
        else{
            callbackError && callbackError()
        }
    } catch (error) {
        const response = error?.response;
        if (response) {
            callbackError && callbackError()
            return;
        }
    }
    isCalling = false;
}

export const logoutAction = () => async (dispatch, getState) => {
    
    try {
        const res = await Axios.post(apiLogout)
    } catch (error) {
        
    }
    let dataLogin = getState().login.dataLogin
    if(!dataLogin){
        // console.log(dataLogin)
        // localStorage.removeItem(OCB_ADMIN_LOGIN)
    }
    else{
        let isCheck = checkSessionLogin(dataLogin)
        if(isCheck) {
            const dataStore = localStorage.getItem(OCB_ADMIN_LOGIN)
            let loginStore = JSON.parse(dataStore)
            // console.log(dataLogin, loginStore)
            localStorage.removeItem(OCB_ADMIN_LOGIN)
        }
    }
    dispatch({type: types.LOGOUT})
}