import React, { useReducer , useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'

// css
import { WrapFilterTab, extendStylesInputSearch } from './index.styles'

//image
import IcBtnDownloadGrey from '../../../../assets/images/IcBtnDownloadGrey';
import IcBtnSearchWhite from '../../../../assets/images/IcBtnSearchWhite';
import IcBtnDownloadWhite from '../../../../assets/images/IcDownloadWhite';
import IcInputSearchblue from '../../../../assets/images/IcSearchBlue';

//component
import Input from '../../../control/Input'
import FilterStatus from './FilterStatus';
import Button from '../../../control/Button';
import DatePickerInput from '../../../control/DatePickerInput';

// action
import { getListCheckPost, exportPostCheck } from '../../../../redux/postcheck/action';

const iconSearch = {
    icon: IcInputSearchblue,
    iconActive: IcInputSearchblue,
    iconError: IcInputSearchblue,
}

const FilterTab = () => {
  const dispatch= useDispatch()

  const {condFilter , listPostCheck} = useSelector(state => ({
    condFilter: state.postcheck.condFilter,
    listPostCheck: state.postcheck.listPostCheck
  }))
 

  const [data, setData] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      startTime: condFilter.startTime,
      endTime: condFilter.endTime,
      reviewStatus: condFilter.reviewStatus,
      customerId: condFilter.customerId,
      filterFrom: "",
      filterSkip: 0,
      filterSize: 15
    })
  const [errorForms, setErrorForms] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      endTime: ''
    }
  )

  const _handleSetValueDropdown = (val) => {
    setData({ reviewStatus: val.key })
  };

  const _handleGetStartDate = (date) => {
    if(date)
      setData({ startTime: date ? moment(date).startOf('day').toDate() : undefined })
  }

  const _handleGetEndDate = (date) => {
    setErrorForms({ endTime: '' })
    if(date)
      setData({ endTime: date ? moment(date).endOf('day').toDate() : undefined })
  }

  const _handleSearch = (e) => {
    let value = e.target.value.trimStart()
    let isSpace = false
    for(let i = 0; i < value.length; i++){      
      if(value[i] === " "){
        if(isSpace){ 
          value = value.substring(0, i) + value.substring(i+1)
          i--
        }
        else isSpace = true
      }
      else isSpace = false
    }
    setData({ customerId: value })
  }

  const _handleValidateSearch = (value, callback) => {
      setData({ customerId: value.trim() })
  }

  const callbackSuccess = () => {}
  const callbackError = () => {}

  const _handleClickBtn = () => {
    dispatch(getListCheckPost({
      ...data,
    }, false, callbackSuccess, callbackError))
  }

  const _handleExport = () => {
    if(listPostCheck?.length > 0) {
      dispatch(exportPostCheck({
        startTime: condFilter.startTime,
        endTime: condFilter.endTime,
        reviewStatus: condFilter.reviewStatus,
        customerId: condFilter.customerId,
      }))
    }
  }

  let minDateFrom = moment(data.endTime).subtract(3, "months").toDate()
  let maxDateTo = moment(data.startTime).add(3, "months").toDate() > new Date ? new Date() : moment(data.startTime).add(3, "months").toDate()

  useEffect(()=> {
    setData({
      startTime: condFilter.startTime,
      endTime: condFilter.endTime,
      reviewStatus: condFilter.reviewStatus,
      customerId: condFilter.customerId
    })
  },[condFilter.startTime, condFilter.endTime, condFilter.reviewStatus, condFilter.customerId])

  return (
    <WrapFilterTab>
      <div className='filter-container'>
        <DatePickerInput
          className="date-picker filter-item"
          defaultValue={data.startTime}
          maxDate={data.endTime}
          minDate={minDateFrom}
          onChangeDate={_handleGetStartDate}
          popperPlacement="bottom"
          uniqueKey="filter-trans-start-date"
        />
        <DatePickerInput
          className="date-picker filter-item"
          defaultValue={data.endTime}
          maxDate={maxDateTo}
          minDate={data.startTime}
          onChangeDate={_handleGetEndDate}
          popperPlacement="bottom"
          err={errorForms.endTime}
          uniqueKey="filter-trans-end-date"
        />
        <FilterStatus
          handleGetValueDropdown={_handleSetValueDropdown}
          className="filter-item list-dropdown"
          defaultKey={data.reviewStatus}
        />
        <Input
          placeholder="Nhập số CIF tìm kiếm"
          className='input-search'
          onChange={_handleSearch}
          handleValidationInput={_handleValidateSearch}
          value={data.customerId}
          maxLength={20}
          inputType="TEXT"
          iconInput={iconSearch}
        />
        <Button
          text='Tìm kiếm'
          iconBtn={<IcBtnSearchWhite />}
          className='btn btn-success'
          onClick={_handleClickBtn}
        />
        <Button
          text='Xuất file'
          iconBtn={listPostCheck.length  > 0 ? <IcBtnDownloadWhite /> : <IcBtnDownloadGrey />}
          className={listPostCheck.length  > 0 ? 'btn btn-success' : 'btn btn-disable'}
          onClick={_handleExport}
        />
      </div>
    </WrapFilterTab>
  )
}

export default FilterTab
