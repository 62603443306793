import React from "react";

export default function IcBtnCancelGreen(props) {
  return (
    <svg
      {...props}
      width={19}
      height={18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4.5L5 13.5"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4.5L14 13.5"
        stroke="#008C44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
