import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from 'react-router-dom'

// css
import { DeltailBlockContainer } from "../../../mainStyles";

// component
import Portal from "../../../control/Portal";
import PopupConfirmUpdateFace from "../../../Popup/PopupConfirmUpdateFace";
import Button from "../../../control/Button";

//helper
import { convertDateTime } from "../../../../helper/convertDateTime";
import { checkTypeTransaction } from "../../../../helper/checkTypeTransaction";

//images
import IcUpdateFace from "../../../../assets/images/IcUpdateFace";
import { apiGetFace } from "../../../../constant/host";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function TransactionDetailBlock({ data, className, dataCIF }) {
    let query = useQuery();
    let customerId = query.get("customerId");

  const [
    isShowModalConfirmUpdateFacePopup,
    setIsShowModalConfirmUpdateFacePopup,
  ] = useState(false);

  const [isErrImg, setIsErrImg] = useState(false);

  const _handleClickBtn = () => {
    setIsShowModalConfirmUpdateFacePopup((pre) => !pre);
  };
  let classNameStatus = "";
  let textStatus = "";
  switch (data?.paymentStatus) {
    case 0: {
      classNameStatus = "status grey";
      textStatus = "Chờ xử lý";
      break;
    }
    case 1: {
      classNameStatus = "status light-green";
      textStatus = "Thành công";
      break;
    }
    case 2: {
      classNameStatus = "status red";
      textStatus = "Thất bại";
      break;
    }
  }

  useEffect(() => {
    setIsErrImg(false);
  }, []);

  return (
    <>
      <DeltailBlockContainer className={className}>
        {data?.type === 1 ? (
          <div className="detail-container">
            <div className="info-block history-info">
              <div className="info-block-item">
                <p className="p-value" title={`#${data?.transactionId}`}>
                  #{data?.transactionId}
                </p>
                <p className="p-title">Mã giao dịch</p>
              </div>
              <div className="info-block-item">
                <p className="p-value">
                  {checkTypeTransaction(data?.type)?.textType}
                </p>
                <p className="p-title">Loại xác thực</p>
              </div>
              <div className="info-block-item">
                <p className="p-value">{data?.DeviceOS}</p>
                <p className="p-title">Loại thiết bị</p>
              </div>
              <div className="info-block-item">
                <p className="p-value" title={data?.customerId}>
                  {data?.customerId}
                </p>
                <p className="p-title">Số CIF</p>
              </div>
              <div className="info-block-item">
                <p className="p-value">
                  {data?.createAt
                    ? convertDateTime(data?.createAt, "DD/MM/YYYY - HH:mm")
                    : ""}
                </p>
                <p className="p-title">Thời gian giao dịch</p>
              </div>
              
              <div className="info-block-item">
                <p className={classNameStatus}>{textStatus}</p>
                <p className="p-title">Trạng thái giao dịch</p>
              </div>  
              <div className="info-block-item">
                <p className="p-value">{data?.DeviceName}</p>
                <p className="p-title">Tên thiết bị</p>
              </div>     
            </div>
          </div>
        ) : (
          <div className="detail-container">
            <div className="face-block">
              <img
                src={apiGetFace(data?.faceId)}
                alt=""
                onError={() => setIsErrImg(true)}
              />
            </div>
            <div className="info-block post-check-info">
              <div className="info-block-item">
                <p className="p-value" title={`#${data?.transactionId}`}>
                  #{data?.transactionId}
                </p>
                <p className="p-title">Mã giao dịch</p>
              </div>
              <div className="info-block-item">
                <p className="p-value">
                  {checkTypeTransaction(data?.type)?.textType}
                </p>
                <p className="p-title">Loại xác thực</p>
              </div>
              <div className="info-block-item">
                <p className="p-value">{data?.DeviceOS}</p>
                <p className="p-title">Loại thiết bị</p>
              </div>
              <div className="info-block-item">
                <p className="p-value">
                  {data?.createAt
                    ? convertDateTime(data?.createAt, "DD/MM/YYYY - HH:mm")
                    : ""}
                </p>
                <p className="p-title">Thời gian giao dịch</p>
              </div>
              <div className="info-block-item">
                <p className={classNameStatus}>{textStatus}</p>
                <p className="p-title">Trạng thái giao dịch</p>
              </div>
              <div className="info-block-item">
                <p className="p-value">{data?.DeviceName}</p>
                <p className="p-title">Tên thiết bị</p>
              </div>
              <div className="info-block-item">
                <p className="p-value" title={data?.customerId}>
                  {data?.customerId}
                </p>
                <p className="p-title">Số CIF</p>
              </div>
            </div>
            {
                customerId?.length > 0 && data?.paymentStatus !== 0 ? (
                <div className="btn-container">
                    <Button
                    text="Thay đổi khuôn mặt"
                    className="btn-update-face"
                    onClick={_handleClickBtn}
                    iconBtn={<IcUpdateFace />}
                    />
                </div>
                ) : (
                <div></div>
            )}
          </div>   
        )}
        
      </DeltailBlockContainer>
      {isShowModalConfirmUpdateFacePopup && (
        <Portal>
          <PopupConfirmUpdateFace
            data={data}
            dataCIF={dataCIF}
            onClickIconClose={_handleClickBtn}
            setIsShowModalPostCheck={setIsShowModalConfirmUpdateFacePopup}
          />
        </Portal>
      )}
    </>
  );
}
