import React, { useEffect, useMemo } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// css
import { WrapBreadcrumbAndTool, WrapBreadcrumb } from "./index.styles";

// constant
import {
  customerDetails,
  historyDetails,
  main,
  postCheck,
  transactionHistory
} from "../../../constant/routes";

//images
import IcArrowRightBlack from "../../../assets/images/IcArrowRightBlack";
import LogoPostCheck from "../../../assets/images/LogoPostCheck";

// action
import { countPostCheck } from "../../../redux/postcheck/action";
import { countTransaction } from "../../../redux/transaction/action";

// helpper
import numberFormatToThousand from "../../../helper/numberFormatToThousand";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const BreadcrumbItem = ({ arrPath }) => {
  return (
    <WrapBreadcrumb>
      {arrPath.map((pathItem, index) => (
        <React.Fragment key={pathItem.value}>
          <p
            onClick={pathItem.onClick}
            style={pathItem.onClick ? { cursor: "pointer" } : {}}
          >
            {pathItem.value}
          </p>
          {arrPath.length - 1 !== index && (
            <IcArrowRightBlack className="icon-text" />
          )}
        </React.Fragment>
      ))}
    </WrapBreadcrumb>
  );
};

const PostCheckBread = () => {
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Hậu kiểm"
        }
      ]} />
    </>
  );
};

const CustomerDetailBread = () => {
  const navigate = useNavigate()
  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Hậu kiểm",
            onClick: () => {
              navigate(`/${main}/${postCheck}`)
            }
          },
          {
            value: "Chi tiết khách hàng",
          },
        ]}
      />
    </>
  );
};

const TransactionHistoryBread = () => {
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Lịch sử giao dịch"
        }
      ]} />
    </>
  );
};

const HistoryDetailBread = () => {
  const navigate = useNavigate()
  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Lịch sử giao dịch",
            onClick: () => {
              navigate(`/${main}/${transactionHistory}`)
            }
          },
          {
            value: "Chi tiết giao dịch",
          },
        ]}
      />
    </>
  );
};

const Breadcrumb = () => {
  
  let query = useQuery();
  let customerId = query.get("customerId");

  const dispatch = useDispatch()

  const {totalPostCheck} = useSelector(state => state.postcheck)
  const { totalListTransaction } = useSelector(state => state.transaction)


  const callbackSuccess = () => { }
  const callbackError = () => { }

  useEffect(() => {
    dispatch(countPostCheck(callbackSuccess, callbackError))
    if(customerId){
      dispatch(countTransaction(customerId, callbackSuccess, callbackError))
    }
  }, [])

  return (
    <WrapBreadcrumbAndTool>
      <div className="wrap-breadcrumb-and-tool">
        <div className="wrap-left-container">
          <div className="logo-post-check">
            <LogoPostCheck />
          </div>
          {useMatch(`/${main}/${postCheck}`) && <PostCheckBread />}
          {useMatch(`/${main}/${postCheck}/${customerDetails()}`) && <CustomerDetailBread />}
          {useMatch(`/${main}/${transactionHistory}`) && <TransactionHistoryBread />}
          {useMatch(`/${main}/${transactionHistory}/${historyDetails()}`) && <HistoryDetailBread />}
        </div>
        <div className="wrap-right-content">
          {useMatch(`/${main}/${postCheck}`) && `${numberFormatToThousand(totalPostCheck)} khách hàng`}
          {useMatch(`/${main}/${postCheck}/${customerDetails()}`) && `${numberFormatToThousand(totalPostCheck)} khách hàng`}
          {useMatch(`/${main}/${transactionHistory}/${historyDetails()}`) && customerId ? `${numberFormatToThousand(totalListTransaction)} giao dịch` : ""}
        </div>
      </div>
    </WrapBreadcrumbAndTool>
  );
};

export default Breadcrumb;
