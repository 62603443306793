import IcFaceOTP from "../assets/images/IcFaceOTP";
import IcSmartOTP from "../assets/images/IcSmartOTP";

export const checkTypeTransaction = (type) => {
  switch (type) {
    case 0:
      return {
        textType: "-",
      };
    case 1:
      return {
        textType: "SmartOTP",
        icon: <IcSmartOTP />
      };
    case 2:
      return {
        textType: "FaceOTP",
        icon: <IcFaceOTP />
      };
    case 3:
      return {
        textType: "FaceLogin",
      };
    default:
      break;
  }
};
