import React from "react";

// styles
import { Bound } from "./index.styles";

// help
import { convertDateTime } from "../../../../helper/convertDateTime";

// image
import { checkTypeTransaction } from "../../../../helper/checkTypeTransaction";

export default function TransactionItem(props) {
  const { className, item, refList, index , onClick} = props;

  let classNameStatus = "";
  let textStatus = "";
  switch (item?.paymentStatus) {
    case 0:
      classNameStatus = "status grey";
      textStatus = "Chờ xử lý";
      break;
    case 1:
      classNameStatus = "status green";
      textStatus = "Thành công";
      break;
    case 2:
      classNameStatus = "status orange";
      textStatus = "Thất bại";
      break;
  }

  return (
    <Bound  className={className} onClick={onClick}>
      <div className="item-check-bold" title={item?.transactionId}>
        #{item?.transactionId ? item?.transactionId : "-"}
      </div>

      <div className="item-check-nomal">
        {checkTypeTransaction(item?.type)?.icon}
        <div>
          {checkTypeTransaction(item?.type)?.textType}
        </div>
      </div>
      <div className="item-check-nomal" title={item?.customerId}>
        {item?.customerId ? item?.customerId: "-"}
      </div>
      <div className="item-check-nomal">
          {convertDateTime(item?.createAt, "DD/MM/YYYY - HH:mm") ? convertDateTime(item?.createAt, "DD/MM/YYYY - HH:mm"): "-"}
      </div>
      <div className={classNameStatus}>{textStatus}</div>
    </Bound>
  );
}
