import React from "react";

export default function TextArea({placeholder, maxLength, onChange, value, ...props}) {
  return (
    <textarea
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={onChange}
      value={value}
    />
  );
}
