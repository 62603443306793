import * as types from "./types";
import moment from "moment";

//data
import { listStatusType } from "../../data/dataPostCheckStatus";
import { LOGOUT } from "../login/types";

const defaultStartDate = moment().startOf("month").toDate();
const defaultEndDate = moment(new Date()).endOf("day").toDate();


const initialState = {
  listPostCheck: [],
  detailPostCheck: {},
  totalPostCheck: 0,
  condFilter: {
    startTime: defaultStartDate,
    endTime: defaultEndDate,
    reviewStatus: listStatusType[0].key,
    customerId: "",
    filterFrom: "",
    filterSize: 15,
    filterSkip: 0,
  },
};

const postcheck = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_CHECK_POST:
        
        return {
            ...state,
            ...action.payload,
            listPostCheck: action.payload.isScrolling ? [...state.listPostCheck, ...action.payload.listPostCheck] : action.payload.listPostCheck,
        };
    case types.CLEAR_LIST_CHECK_POST:
        return {
            ...state,
            listPostCheck: [],
            condFilter: initialState.condFilter
        };
    case types.GET_DETAIL_CHECK_POST:
        return {
            ...state,
            detailPostCheck: action.detailPostCheck,
        };
    case types.CLEAR_DETAIL_CHECK_POST:
        return {
            ...state,
            detailPostCheck: {}
        };
    case types.UPDATE_CHECK_POST_SUCCESS:
        return {
            ...state,
            detailPostCheck: {},
        }
    case types.RESET_CONDFILTER:
        return {
            ...state,
            condFilter: initialState.condFilter
        };
    case types.COUNT_LIST_POST_CHECK: 
        return {
            ...state,
            totalPostCheck: action.totalPostCheck
        }
    case types.UPDATE_FACE_POST_CHECK_SUCCESS: 
        return {
            ...state,
            detailPostCheck: {}
        }
    case LOGOUT:
        return initialState
        
    default:
      return state;
  }
};

export default postcheck;
