import React, { useState, useEffect } from "react";

// styles
import { Bound } from "./index.styles";

// help
import { convertDateTime } from "../../../../helper/convertDateTime";

// container
import FaceZoom from "../../../control/FaceZoom";

//host
import { apiGetAvatar } from "../../../../constant/host";

// image
import IcAvatarDefault from "../../../../assets/images/IcAvatarDefault";

export default function PostCheckItem(props) {
  const { className, item, refList, index , onClick} = props;

  const [isErrImg, setIsErrImg] = useState(false);

  let classNameStatus = "";
  let textStatus = "";
  switch (item?.reviewStatus) {
    case 0:
      classNameStatus = "status grey";
      textStatus = "Chưa hậu kiểm";
      break;
    case 1:
      classNameStatus = "status green";
      textStatus = "Hậu kiểm thành công";
      break;
    case 2:
      classNameStatus = "status orange";
      textStatus = "Hậu kiểm thất bại";
      break;
    case 3:
      classNameStatus = "status red";
      textStatus = "OCB xử lý sau";
      break;
  }

  useEffect(() => {
    setIsErrImg(false);
  }, [item?.avatarId]);

  return (
    <Bound  className={className} onClick={onClick}>
      <div className="item-check-bold">
        {index < 9 ? `0${index + 1}` : index + 1}
      </div>
      <div className="item-check-bold" title={item?.customerId}>
        {item?.customerId ? item?.customerId : "-"}
      </div>
      {/* <div
        className="item-check-img"
        style={{ backgroundImage: `url(${item?.avatarId})` }}
      ></div> */}
      {!isErrImg && item?.avatarId ? (
        <>
          <FaceZoom
            className="item-check-img"
            url={apiGetAvatar(item?.avatarId)}
            refList={refList}
            onError={() => setIsErrImg(true)}
          />
        </>
      ) : (
        <IcAvatarDefault />
      )}

      <div className="item-check-nomal">
        {item?.createAt
          ? convertDateTime(item?.createAt, "DD/MM/YYYY - HH:mm")
          : ""}
      </div>
      <div className="item-check-nomal" title={item?.reviewer}>
        {/* {item?.reviewer ? item?.reviewer : "-"} */}
        {item?.reviewStatus !== 0 ? item?.reviewer: "-"}
      </div>
      <div className="item-check-nomal">
        {/* {item?.reviewAt
          ? convertDateTime(item?.reviewAt, "DD/MM/YYYY - HH:mm")
          : "-"} */}
          {item?.reviewStatus !== 0 ? convertDateTime(item?.reviewAt, "DD/MM/YYYY - HH:mm"): "-"}

      </div>
      <div className={classNameStatus}>{textStatus}</div>
    </Bound>
  );
}
