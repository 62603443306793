import React, { useState, useEffect } from 'react'

// styles
import { Bound } from './index.styles'

// help
import { convertDateTime } from '../../../../helper/convertDateTime'

// component
import FaceZoom from '../../../control/FaceZoom'
import { apiGetFace } from '../../../../constant/host'

// image
import IcAvatarDefault from '../../../../assets/images/IcAvatarDefault'

export default function CustomHistoryItem(props) {

    const { className, item, refList, index, onClick} = props

    const [isErrImg, setIsErrImg] = useState(false)

    let classNameStatus = ''
    let textStatus = ''
    switch (item?.paymentStatus) {
        case 0: 
            classNameStatus="status grey";
            textStatus="Chờ xử lý";
            break;
        case 1:
            classNameStatus="status green";
            textStatus="Thành công";
            break;
        case 2:
            classNameStatus="status red";
            textStatus="Thất bại";
           break;
    }

    useEffect(() => {
        setIsErrImg(false)
    },[])

    return (
        <Bound className={className} onClick={onClick}>
            <div>{(index < 9) ? `0${index + 1}` : index + 1}</div>
            <div className='item-transaction-cif' title={`${item?.customerId}`}> 
                {item?.customerId ? item?.customerId : "-"}
            </div>
            <div title={`#${item?.transactionId}`}>
                #{item?.transactionId ? item?.transactionId : "-"}
            </div>
            {/* <div className='item-transaction-img' style={{backgroundImage:`url(${item?.imgTransaction})`}}></div> */}

            {
                !isErrImg && item?.faceId ? 
                (
                    <FaceZoom className='item-transaction-img' 
                        url={apiGetFace(item?.faceId)} 
                        refList={refList} 
                        onError={() => setIsErrImg(true)}
                    />
                ): (
                    <IcAvatarDefault className='item-transaction-img' />
                )
            }
            
            <div 
                className='item-transaction-time' 
                title={item?.createAt}>{item?.createAt ? convertDateTime(item?.createAt, "DD/MM/YYYY - HH:mm") : "-"}
            </div>
            <div className={classNameStatus}>{textStatus}</div>
        </Bound>
    )
}
