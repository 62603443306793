import * as types from './types'
import { postCheck, customerHistory, transactionHistory } from '../../constant/keys';

const initialState = {
    postCheck: {
        scrollTop: 0
    },
    customerHistory: {
        scrollTop: 0
    },
    transactionHistory: {
        scrollTop: 0
    }
}

const scrolling = (state = initialState, action) => {
    const { type, payload } = action;
    if(type === types.SET_SCROLL){
        switch (payload.key) {
            case postCheck:
                return {
                    ...state,
                    postCheck: {
                        scrollTop: payload.scrollTop
                    }
                }
            case customerHistory:
                return {
                    ...state,
                    customerHistory: {
                        scrollTop: payload.scrollTop
                    }
                }
            case transactionHistory:
                return {
                    ...state,
                    transactionHistory: {
                        scrollTop: payload.scrollTop
                    }
                }
            default:
                break;
        }
    }
    if(type === types.CLEAR_SCROLL)
    switch (payload.key) {
        case postCheck:
            return {
                ...state,
                postCheck: initialState.postCheck
            }
        case customerHistory:
            return {
                ...state,
                customerHistory: initialState.customerHistory
            }
        case transactionHistory:
            return {
                ...state,
                transactionHistory: initialState.transactionHistory
            }
        default:
            break;
    }
    return state;
}

export default scrolling