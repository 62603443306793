import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Main from "./components/Main";
import CustomerDetails from "./components/Main/CustomerDetails";
import HistoryDetails from "./components/Main/HistoryDetails";
import PostCheck from "./components/Main/PostCheck";
import TransactionHistory from "./components/Main/TransactionHistory"
import PrivateRoute from "./components/PrivateRoute";
import {
  customerDetails,
  historyDetails,
  main,
  postCheck,
  transactionHistory
} from "./constant/routes";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route
        path={`/${main}/*`}
        element={
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        }
      >
        <Route
          path={postCheck}
          element={
            <PrivateRoute>
              <PostCheck />
            </PrivateRoute>
          }
        />
        <Route
          path={transactionHistory}
          element={
            <PrivateRoute>
              <TransactionHistory />
            </PrivateRoute>
          }
        />
        <Route
          path={`${postCheck}/${customerDetails()}`}
          element={
            <PrivateRoute>
              <CustomerDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={`${transactionHistory}/${historyDetails()}`}
          element={
            <PrivateRoute>
              <HistoryDetails />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to={`/${main}/${postCheck}`} />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
