import React from 'react'
import { useMatch } from "react-router-dom";

// constant
import { customerDetails, historyDetails, main, postCheck, transactionHistory } from '../../../constant/routes'

// css
import { WrapTitlePage } from './index.styles'

const TitlePage = () => {
    return (
        <WrapTitlePage>
            {
                useMatch(`/${main}/${postCheck}`) && 
                <div className='hero-container'>
                    <p>Hậu kiểm</p>
                </div>
            }
            {
                useMatch(`/${main}/${postCheck}/${customerDetails()}`) && (
                <div className='hero-container'>
                    <p>Chi tiết khách hàng</p>
                </div>
            )}
            {
                useMatch(`/${main}/${transactionHistory}`) && 
                <div className='hero-container'>
                    <p>Lịch sử giao dịch</p>
                </div>
            }
            {
                useMatch(`/${main}/${transactionHistory}/${historyDetails()}`) && (
                <div className='hero-container'>
                    <p>Chi tiết giao dịch</p>
                </div>
            )}
        </WrapTitlePage>
    )
}

export default TitlePage