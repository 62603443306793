import React from "react";

export default function IcUpdateFace(props) {
  return (
    <svg
      {...props}
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58333 13.4167L2.25 11.0833L4.58333 8.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 7.58301V8.74967C12.75 9.36851 12.5042 9.96201 12.0666 10.3996C11.629 10.8372 11.0355 11.083 10.4167 11.083H2.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4167 0.583008L12.7501 2.91634L10.4167 5.24967"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 6.41699V5.25033C2.25 4.63149 2.49583 4.03799 2.93342 3.60041C3.371 3.16282 3.96449 2.91699 4.58333 2.91699H12.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
