import React from 'react'

// data
import { listStatusTypeTransaction } from '../../../../../data/dataTransactionStatus'

//images
import IcFlagBlue from '../../../../../assets/images/IcFlagBlue'

//component
import InputDropdown from '../../../../control/InputDropdown'
import { listStatusTransactionOTP } from '../../../../../data/dataTransactionOTP'



const iconStore = {
    icon: IcFlagBlue,
    iconActive: IcFlagBlue,
    iconError: IcFlagBlue,
}


const FilterStatusOTP = ({ handleGetValueDropdown, defaultKey, className }) => {

    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }

    return (
        <InputDropdown
            className={className}
            iconInput={iconStore}
            getValueDropdown={_handleSetValueDropdown}
            dataFilterOption={listStatusTransactionOTP}
            objChoice={listStatusTransactionOTP.find((item)=>item.key === defaultKey) || null}
        />
    )
}

export default FilterStatusOTP
