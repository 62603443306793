import React, { useRef, useMemo, useEffect } from 'react'
import { useParams , useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// css
import { WrapCustomerDetail, TableHeaderCustomerHistory, TableContentCustomerHistory } from './index.styles'
import { WrapPage, TitleSessionContainer } from '../../mainStyles'

// component
import Breadcrumb from '../../control/Breadcrumb'
import Hero from '../../control/Hero'
import DetailBlock from './DetailBlock'
import EmptyDataControl from '../../control/EmptyDataControl'
import CustomHistoryItem from './CustomHistoryItem'
import useScrollEnd from '../../../hook/useScrollEnd'

// routes
import { main, transactionHistory, historyDetails } from '../../../constant/routes'


// image 
import IcNodata from '../../../assets/images/IcNoData'

// action
import { countTransaction, getListTransactionHistory } from '../../../redux/transaction/action'
import { enableLoading, disableLoading } from '../../../redux/loading/action'
import { setScroll } from '../../../redux/scrolling/action'
import { getDetailCheckPost, clearDetailCheckPost } from '../../../redux/postcheck/action'


const dataHeader = [
    "Số thứ tự",
    "Số CIF",
    "Mã giao dịch",
    "Hình ảnh giao dịch",
    "Thời gian giao dịch",
    "Kết quả giao dịch"
]

let isCustomerDetailLoaded = false
let isCustomerHistoryLoaded = false

let timer = null

const CustomerDetails = () => {

    const listRef = useRef(null)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { customerId } = useParams()
    
    const { detailPostCheck } = useSelector(state => state.postcheck)

    useEffect(() => {
        initData()
        return () => {
            clearData()
        }
    },[])

    const { listTransaction , condFilter, customerHistoryScroll} = useSelector(state => ({
        listTransaction: state.transaction.listTransaction,
        condFilter: state.transaction.condFilter,
        customerHistoryScroll: state.scrolling.customerHistory
    }))

    const initData = () => {
        const callbackInitData = () => {
            if(isCustomerDetailLoaded && isCustomerHistoryLoaded){
                setTimeout(() => {
                    dispatch(disableLoading())
                }, 500);
            }
        }

        let newCondFilter = {
            ...condFilter,
            id: customerId,
            filterFrom: "",
            filterSkip: 0,
            filterSize: condFilter.filterSkip + condFilter.filterSize
        }
        const callbackGetHistory = (isSuccess) => {
            isCustomerHistoryLoaded = true
            if(isSuccess && listRef.current){
                listRef.current.scrollTop = customerHistoryScroll.scrollTop
            }
            callbackInitData()
        }
        
        const callbackGetDetail = () => {
            isCustomerDetailLoaded = true
            callbackInitData()

            dispatch(getListTransactionHistory(newCondFilter, false, callbackGetHistory(true), callbackGetHistory(false), true))
        }

        isCustomerDetailLoaded = false
        isCustomerHistoryLoaded = false
        dispatch(enableLoading())
        dispatch(getDetailCheckPost({id: customerId}, callbackGetDetail, callbackGetDetail))
    }

    const clearData = () => {
        // dispatch(clearListTransaction())
        dispatch(clearDetailCheckPost())
    }

    const _handleScroll = (scrollTop) => {
        if(timer) clearTimeout(timer)
        timer = setTimeout(() => {
            dispatch(setScroll({key: 'customer-history', scrollTop}))
        }, 500);
    }

    useScrollEnd({scrollEleRef: listRef, callback: (callback) => {
        dispatch(getListTransactionHistory(
            {   
                ...condFilter,
                filterFrom: listTransaction[listTransaction.length - 1].id,
                filterSkip: listTransaction.length,
                filterSize: 15
            },
        true, callback))
        dispatch(countTransaction(customerId))
    }, dependencies: [listTransaction], dependenciesScroll: [listTransaction?.length], callBackScroll: _handleScroll})


    const _handleClickBtn = (historyId) => {
        navigate(`/${main}/${transactionHistory}/${historyDetails(historyId)}?customerId=${customerId}`)
    }

    const tableHeader = useMemo(() => (
        listTransaction?.length > 0 &&
            dataHeader.map((item, i) => (
                <div className="table-header" key={i}>
                    {item}
                </div>
            ))
    ), [JSON.stringify(listTransaction)])


    const tableContent = useMemo(() => (
        listTransaction?.length === 0 ?
            <div className="wrap-empty">
                <EmptyDataControl 
                    IconNonData={<IcNodata className='ic-no-data' />}
                    labelDes='Khách hàng chưa phát sinh giao dịch'/>
            </div>
            :
            <div className="wrap-list-trans" ref={listRef}>
                <div className="list-trans">
                {
                    listTransaction?.map((item, i) => (
                    <CustomHistoryItem key={i} index={i} className='history-item'
                        item={item} refList={listRef} onClick={(e) => {
                            _handleClickBtn(item.id)
                        }}/>
                    ))
                }
                </div>
            </div>
    ), [JSON.stringify(listTransaction)])

    return (
        <WrapPage>
            <Breadcrumb />
            <Hero />
            <WrapCustomerDetail>
                <TitleSessionContainer>
                    <p className='title'>Thông tin khách hàng</p>
                </TitleSessionContainer>
                <DetailBlock data={detailPostCheck} />
                <TitleSessionContainer>
                    <p className='title' style={{marginTop: "24px"}}>Lịch sừ giao dịch</p>
                </TitleSessionContainer>
                <TableHeaderCustomerHistory>
                    {tableHeader}
                </TableHeaderCustomerHistory>
                <TableContentCustomerHistory>
                    {tableContent}
                </TableContentCustomerHistory>
            </WrapCustomerDetail>
        </WrapPage>
    )
}

export default CustomerDetails