import * as types from './types'
import Axios from 'axios';
import { host } from '../../constant/host'

// helper
import mapErrors from '../../helper/mapErrors';
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import checkSessionLogin from '../../helper/checkSessionLogin';

// action 
import { logoutAction } from '../login/action';
import autoDownloadFromBlob from '../../helper/autoDownloadFromBlob';
import { disableLoading, enableLoading } from '../loading/action';

// api
const apiTransactionFilter = `${host}/manage/transaction/filter`
const apiTransactionDetail = `${host}/manage/transaction/details`
const apiTransactionCount = `${host}/manage/transaction/count`
const apiTransactionExport = `${host}/manage/transaction/export`
 
const headerDataRequest = {
    cid: "WebPortal",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false

export const getListTransaction = (
    dataTransaction = {
        id: "",
        keySearch: "",
        type: "",
        paymentStatus: "",
        startTime: "",
        endTime: "",
        filterFrom: "",
        filterSkip: 0,
        filterSize: 15
    },
    isScrolling = false,
    callbackSuccess,
    callbackError,
    notCheckSessionLogin = false
) => async (dispatch, getState) => { 
    let dataLogin = getState().login.dataLogin
    if(!notCheckSessionLogin){
        if(!checkSessionLogin(dataLogin)) {
            dispatch(logoutAction())
            return
        }
    }
    if(isCalling) return
    isCalling = true
    if(!isScrolling) dispatch(enableLoading())

    let condFilterList = {...dataTransaction}
    let dataRequest = {...headerDataRequest, data: condFilterList}

    try {
        const res = await Axios.post(apiTransactionFilter, dataRequest)
        const {data} = res
        
        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.GET_LIST_TRANSACTION,
                payload: {
                    listTransaction : data.data.elements || [],
                    condFilterList,
                    isScrolling
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_LIST_TRANSACTION_ERROR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_ERROR))
        callbackError && callbackError()
    }
    if(!isScrolling) setTimeout(() => {
        dispatch(disableLoading())
    }, 500); 
    isCalling = false
}

export const getListTransactionHistory = (
    dataTransaction = {
        id: "",
        filterFrom: "",
        filterSkip: "",
        filterSize: "",
    },
    isScrolling = false,
    callbackSuccess,
    callbackError,
    notCheckSessionLogin = false
) => async (dispatch, getState) => { 
    let dataLogin = getState().login.dataLogin
    if(!notCheckSessionLogin){
        if(!checkSessionLogin(dataLogin)) {
            dispatch(logoutAction())
            return
        }
    }
    if(isCalling) return
    isCalling = true
    if(!isScrolling) dispatch(enableLoading())

    let condFilter = {...dataTransaction}
    let dataRequest = {...headerDataRequest, data: condFilter}

    try {
        const res = await Axios.post(apiTransactionFilter, dataRequest)
        const {data} = res
        
        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.GET_LIST_TRANSACTION,
                payload: {
                    listTransaction : data.data.elements || [],
                    condFilter,
                    isScrolling
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_LIST_TRANSACTION_ERROR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_TRANSACTION_ERROR))
        callbackError && callbackError()
    }
    if(!isScrolling) setTimeout(() => {
        dispatch(disableLoading())
    }, 500); 
    isCalling = false
}


export const clearListTransaction = () => async (dispatch) => {
    dispatch({type: types.CLEAR_LIST_TRANSACTION})
}

export const resetListTransaction = (callbackSuccess) => async dispatch => {
    dispatch({
      type: types.RESET_CONDFILTER_TRANSACTION,
    })
  
    callbackSuccess && callbackSuccess()
}

export const getDetailTransaction = (  
    historyId,
    callbackSuccess,
    callbackError,
    notCheckSessionLogin = false
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!notCheckSessionLogin){
        if(!checkSessionLogin(dataLogin)) {
            dispatch(logoutAction())
            return
        }
    }
    if(isCalling) return
    isCalling = true
    let data = {id: historyId}
    let dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiTransactionDetail, dataRequest)
    
        const { data } = res
        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.GET_DETAIL_TRANSACTION,
                detailTransaction: data.data.element
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_DETAIL_TRANSACTION_ERROR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_TRANSACTION_ERROR))
        callbackError && callbackError()
    }
    isCalling = false
}

export const clearDetailTransaction = () => async (dispatch) => {
    dispatch({type: types.CLEAR_DETAIL_TRANSACTION})
}

export const countTransaction = (
    id,
    callbackSuccess,
    callbackError
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!checkSessionLogin(dataLogin)) return

    let data = {id: id}
    let dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiTransactionCount, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.COUNT_TRANSACTION,
                totalListTransaction: data.data.total
            })
            callbackSuccess && callbackSuccess()
        }else {
            dispatch({type: types.COUNT_TRANSACTION_ERROR})
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.COUNT_TRANSACTION_ERROR))
        callbackError && callbackError()
    }
}

export const exportTransaction = (
    dataTransaction = {
        customerId: "",
        transactionId: "",
        type: "",
        paymentStatus: "",
        startTime: "",
        endTime: "",
    }
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!checkSessionLogin(dataLogin)) {
        dispatch(logoutAction())
        return
    }

    let data = {...dataTransaction}
    let dataRequest = {...headerDataRequest, data}
    try {
        const res = await Axios.post(apiTransactionExport, dataRequest , {
            responseType: 'blob'
        })
        const {data} = res
        autoDownloadFromBlob(data, "transaction")
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_TRANSACTION_ERROR))
    }
}