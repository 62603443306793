export const checkStatus = (reviewStatus) => {
  switch (reviewStatus) {
    case 0:
      return {
        classNameStatus: "status grey",
        textStatus: "Chưa hậu kiểm",
      };
    case 1:
      return {
        classNameStatus: "status green",
        textStatus: "Hậu kiểm thành công",
      };
    case 2:
      return {
        classNameStatus: "status red",
        textStatus: "Hậu kiểm thất bại",
      };
    case 3:
      return {
        classNameStatus: "status red",
        textStatus: "Hậu kiểm thất bại, OCB xử lý sau",
      };
      default:
        break;
  }
};
