import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

// styles
import { WrapPopupConfirmUpdateFace } from './index.styles'
import { OverlayFullScreen } from '../../mainStyles'

//hook
import { useOnClickOutside } from '../../../hook/useClickOutside'

//images
import IcClose from '../../../assets/images/IcClose'
import IcBtnCancelGreen from '../../../assets/images/IcBtnCancelGreen'
import IcBtnSubmitWhite from '../../../assets/images/IcBtnSubmitWhite'

//component
import Button from '../../control/Button'

// action
import { getDetailCheckPost, updateFacePostCheck } from '../../../redux/postcheck/action'
import { getDetailTransaction } from '../../../redux/transaction/action'

const PopupConfirmUpdateFace = ({ data, onClickIconClose, setIsShowModalPostCheck ,dataCIF}) => {

    const dispatch = useDispatch()
    const ref = useRef(null)

    const callbackSuccess = () =>{
        setIsShowModalPostCheck && setIsShowModalPostCheck()
        dispatch(getDetailCheckPost({id: dataCIF.id}))

    }

    const callbackError = () => {}

    const handleSubmit = () =>{
        dispatch(updateFacePostCheck({srcId: data.id , destId: dataCIF.id}, callbackSuccess, callbackError))
    }

    useOnClickOutside(ref, ()=> setIsShowModalPostCheck())

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupConfirmUpdateFace ref={ref}>
                    <div className="icon-close" 
                        onClick={onClickIconClose} >
                        <IcClose/>        
                    </div>
                    <div className="title">Thay thế khuôn mặt</div>
                    <div className="sub-title">
                        Bạn có chắc chắn muốn dùng khuôn mặt này để thay thế cho hình ảnh gốc của khách hàng CiF: <span className='span-bold'>{dataCIF?.customerId}</span>
                    </div>
                    <div className='btn-container'>
                        <div 
                            onClick={onClickIconClose}>
                                <Button
                                    className="btn-cancel"
                                    text='Hủy bỏ'
                                    iconBtn={<IcBtnCancelGreen/>}
                                />
                        </div>
                        <div 
                            onClick={onClickIconClose}
                        >
                                <Button
                                    onClick={handleSubmit}
                                    className="btn-submit"
                                    text="Đồng ý"
                                    iconBtn={<IcBtnSubmitWhite/>}
                                />        
                        </div>
                    </div>
                    
                </WrapPopupConfirmUpdateFace>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupConfirmUpdateFace
